<template>
  <div
    v-if="isOpen"
    class="modal fade show"
    aria-labelledby="addNewCardTitle"
    style="display: block; background: #1c1d1c33"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div
              class="d-flex justify-content-between px-1 my-1 align-item-center"
            >
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                @click="toggleModal"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0" />

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-12">
                <label class="form-label" for="chartName">Branch Name</label>
                <div class="input-group">
                  <vField
                    type="text"
                    name="branch_name"
                    v-model="formData.branch_name"
                    class="form-control add-credit-card-mask"
                    placeholder="Branch Name"
                  />
                  <ErrorMessage
                    name="branch_name"
                    class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="chartName">Branch Address</label>
                <div class="input-group">
                  <vField
                    as="textarea"
                    name="branch_address"
                    v-model="formData.branch_address"
                    class="form-control"
                    placeholder="Branch Address"
                  />
                  <ErrorMessage
                    name="branch_address"
                    class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="chartName">Branch Email</label>
                <div class="input-group">
                  <vField
                    type="text"
                    name="branch_email"
                    v-model="formData.branch_email"
                    class="form-control add-credit-card-mask"
                    placeholder="Branch Email"
                  />
                  <ErrorMessage
                    name="branch_email"
                    class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="chartName"
                  >Branch Contact No</label
                >
                <div class="input-group">
                  <vField
                    type="text"
                    name="branch_contact_no"
                    v-model="formData.branch_contact_no"
                    class="form-control add-credit-card-mask"
                    placeholder="Branch Contact No"
                  />
                  <ErrorMessage
                    name="branch_contact_no"
                    class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="chartName">Branch ID</label>
                <div class="input-group">
                  <vField
                    type="text"
                    name="branch_id"
                    v-model="formData.branch_id"
                    class="form-control add-credit-card-mask"
                    placeholder="Branch ID"
                  />
                  <ErrorMessage
                    name="branch_id"
                    class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2" />
              <button
                :disabled="loading"
                type="submit"
                class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button
                @click.prevent="toggleModal"
                class="btn btn-outline-secondary mt-1 waves-effect"
              >
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import objToArray from "@/services/utils/object-to-array";
import { ref, computed } from "@vue/runtime-core";

const isOpen = ref(false);
const loading = ref(false);
const emit = defineEmits(['onSubmitForm', 'onCloseBranchModal'])

const props = defineProps({
  formData: Object,
  showParent: {
    type: Boolean,
    default: false,
  },
  branch: Array,
  modalTitle: {
    type: String,
    default: "Add Branch",
  },
  // submitEvent: {
  //   default: "onSubmitBranch",
  // },
});
const schema = {
  branch_name: "required|min:3|max:100",
  branch_address: "required|min:3|max:300",
  branch_email: "required|min:3|max:100",
  branch_contact_no: "required|min:3|max:13",
  branch_id: "required|min:3|max:100",
};

function submit() {
  emit('onSubmitForm');
}

function toggleModal() {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    emit("onCloseBranchModal");
  }
}

defineExpose({
  toggleModal
})
</script>
